/**
 * Created by rhett on 2017/8/17.
 */

export default class Notify
{
  static LOGIN_COMPLETE = "LOGIN_COMPLETE";

  static VIDEO_LIST_CHANGED = "VIDEO_LIST_CHANGED";

  static PAGE_REACH_BOTTOM = "PAGE_REACH_BOTTOM";

  static SEARCH_KEYWORD_CHANGED = "SEARCH_KEYWORD_CHANGED";

  static COMMENTS_LIST_CHANGED = "COMMENTS_LIST_CHANGED";


  constructor(name, target, data)
  {
    this.name = name;
    this.target = target;
    this.data = data;
  }

  //通知名
  name = "";
  //发送者
  target = null;
  //携带数据
  data = null;


}